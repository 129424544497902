import Vue from 'vue';
import axios from 'axios';

const state = {
  jobTypes: [
    {
      databaseName: 'activation',
      type: 'Panfletagem / Ativação',
      file: '/vue_form/activation.png',
      minPrice: 250,
      imageRights: false,
      titleExample: 'Ex: Panfletagem para divulgação de produtos, serviços, etc',
      descriptionPoints: [
        'Qual o produto ou serviço que será divulgado?',
        'Quais as atividades e trajes dos promotores?',
      ]
    },
    {
      databaseName: 'event',
      type: 'Eventos / Promotores',
      file: '/vue_form/event.png',
      minPrice: 250,
      imageRights: false,
      titleExample: 'Ex: Exposição de Carro, Promotor para Atendimento',
      descriptionPoints: [
        'Qual o produto que será divulgado?',
        'Quais as atividades e trajes dos promotores?',
        'Especifique se busca promotores bilíngue/trilíngue.'
      ]
    },
    {
      databaseName: 'fashion',
      type: 'Desfile',
      file: '/vue_form/fashion.png',
      minPrice: 450,
      imageRights: true,
      titleExample: 'Ex: Desfile de moda de praia',
      descriptionPoints: []
    },
    {
      databaseName: 'photo',
      type: 'Foto / Catálogo',
      file: '/vue_form/photo.png',
      minPrice: 450,
      imageRights: true,
      titleExample: 'Ex: Fotos para Lookbook E-commerce',
      descriptionPoints: [
        'Qual produtos será divulgado?',
        'Onde as fotos serão divulgadas?',
        'Qual o tipo de foto e a quantidade de looks?'
        ]
      },
    {
      databaseName: 'video',
      type: 'Vídeo',
      file: '/vue_form/video.png',
      minPrice: 250,
      imageRights: true,
      titleExample: 'Ex: Video Corporativo, sem fala',
      descriptionPoints: [
        'Qual o produto que será divulgado?',
        'Qual a duração do vídeo e onde será divulgado?',
        'Qual é o papel dos contratados?',
        'Atuação com ou sem fala?'
      ]
    },
    {
      databaseName: 'showroom',
      type: 'Show Room / Modelo de Prova',
      file: '/vue_form/showroom.png',
      minPrice: 300,
      imageRights: false,
      titleExample: 'Ex: Show Room, 5 pares de sapato',
      descriptionPoints: []
    }
  ],
  sexOptions: [
    {
      text: 'Masculino',
      value: 'male'
    },
    {
      text: 'Feminino',
      value: 'female'
    },
    {
      text: 'Unissex',
      value: 'unisex'
    }
  ],
  fashionModelProfiles: {
    female: [
      {
        name: 'Fashion',
        img: '/vue_form/profile-fashion.png',
        custom: false,
        attributes: {
          heightRange: {
            min: 175,
            max: 190,
            text: 'a partir de 1,75m'
          },
          suiteDressSizeRange: {
            min: 34,
            max: 38,
            text: 'De 34 a 38'
          },
          bustRange: {
            min: 76,
            max: 95,
            text: 'De 76cm a 95cm'
          },
          waistRange: {
            min: 60,
            max: 72,
            text: 'De 60cm a 72cm'
          },
          hipsRange: {
            min: 85,
            max: 95,
            text: 'De 85cm a 95cm'
          },
          shoesSizeRange: {
            min: 34,
            max: 44,
            text: 'De 34 a 44'
          }
        }
      },
      {
        name: 'Comercial',
        img: '/vue_form/profile-comercial.png',
        custom: false,
        attributes: {
          heightRange: {
            min: 160,
            max: 180,
            text: 'De 1,60m a 1,80m'
          },
          suiteDressSizeRange: {
            min: 34,
            max: 40,
            text: 'De 34 a 40'
          },
          bustRange: {
            min: 76,
            max: 95,
            text: 'De 76cm a 95cm'
          },
          waistRange: {
            min: 60,
            max: 77,
            text: 'De 60cm a 77cm'
          },
          hipsRange: {
            min: 85,
            max: 100,
            text: 'De 85cm a 100cm'
          },
          shoesSizeRange: {
            min: 34,
            max: 44,
            text: 'De 34 a 44'
          }
        }
      },
      {
        name: 'Encorpado',
        img: '/vue_form/profile-fullbody.png',
        custom: false,
        attributes: {
          heightRange: {
            min: 160,
            max: 210,
            text: 'De 1,60m a 2,10m'
          },
          suiteDressSizeRange: {
            min: 38,
            max: 42,
            text: 'De 38 a 42'
          },
          bustRange: {
            min: 90,
            max: 105,
            text: 'De 90cm a 105cm'
          },
          waistRange: {
            min: 60,
            max: 78,
            text: 'Até 78cm'
          },
          hipsRange: {
            min: 95,
            max: 120,
            text: 'De 95cm a 120cm'
          },
          shoesSizeRange: {
            min: 34,
            max: 44,
            text: 'De 34 a 44'
          }
        }
      },
      {
        name: 'Plus Size',
        img: '/vue_form/profile-plus-size.png',
        custom: false,
        attributes: {
          heightRange: {
            min: 160,
            max: 210,
            text: 'De 1,60m a 2,10m'
          },
          suiteDressSizeRange: {
            min: 42,
            max: 48,
            text: 'De 42 a 48'
          },
          bustRange: {
            min: 85,
            max: 140,
            text: 'Acima de 85cm'
          },
          waistRange: {
            min: 75,
            max: 140,
            text: 'Acima de 75cm'
          },
          hipsRange: {
            min: 110,
            max: 140,
            text: 'Acima de 110cm'
          },
          shoesSizeRange: {
            min: 34,
            max: 44,
            text: ' De 34 a 44'
          }
        }
      },
      {
        name: 'Personalizado',
        img: '/vue_form/profile-custom-female.jpg',
        custom: true,
        attributes: {
          heightRange: {
            min: 150,
            max: 210,
            text: 'Customizado'
          },
          suiteDressSizeRange: {
            min: 34,
            max: 42,
            text: 'Customizado'
          },
          bustRange: {
            min: 76,
            max: 110,
            text: 'Customizado'
          },
          waistRange: {
            min: 60,
            max: 110,
            text: 'Customizado'
          },
          hipsRange: {
            min: 85,
            max: 130,
            text: 'Customizado'
          },
          shoesSizeRange: {
            min: 34,
            max: 44,
            text: 'Customizado'
          }
        }
      }
    ],
    male: [
      {
        name: 'Padrão Masculino',
        custom: false,
        img: '/vue_form/profile-standard-male.jpg',
        attributes: {
          heightRange: {
            min: 155,
            max: 210,
            text: 'De 1,55m a 2,10m'
          },
          suiteDressSizeRange: {
            min: 34,
            max: 46,
            text: 'De 34 a 46'
          },
          bustRange: {
            min: 76,
            max: 140,
            text: 'De 76cm a 140cm'
          },
          waistRange: {
            min: 60,
            max: 100,
            text: 'De 60cm a 100cm'
          },
          hipsRange: {
            min: 85,
            max: 140,
            text: 'De 85cm a 140cm'
          },
          shoesSizeRange: {
            min: 34,
            max: 44,
            text: 'De 34 a 44'
          }
        }
      },
      {
        name: 'Personalizado',
        custom: true,
        img: '/vue_form/profile-custom-male.jpg',
        attributes: {
          heightRange: {
            min: 155,
            max: 210,
            text: 'Customizado'
          },
          suiteDressSizeRange: {
            min: 34,
            max: 46,
            text: 'Customizado'
          },
          bustRange: {
            min: 76,
            max: 140,
            text: 'Customizado'
          },
          waistRange: {
            min: 60,
            max: 140,
            text: 'Customizado'
          },
          hipsRange: {
            min: 85,
            max: 140,
            text: 'Customizado'
          },
          shoesSizeRange: {
            min: 34,
            max: 44,
            text: 'Customizado'
          }
        }
      }
    ],
    unisex: [
      {
        name: 'Padrão Unisex',
        custom: false,
        img: '/vue_form/profile-unisex.png',
        attributes: {
          heightRange: {
            min: 150,
            max: 210,
            text: 'De 1,50m a 2,10m'
          },
          suiteDressSizeRange: {
            min: 34,
            max: 46,
            text: 'De 34 a 46'
          },
          bustRange: {
            min: 76,
            max: 140,
            text: 'De 76cm a 140cm'
          },
          waistRange: {
            min: 60,
            max: 140,
            text: 'De 60cm a 140cm'
          },
          hipsRange: {
            min: 85,
            max: 140,
            text: 'De 85cm a 140cm'
          },
          shoesSizeRange: {
            min: 33,
            max: 48,
            text: 'De 33 a 48'
          }
        }
      },
      {
        name: 'Personalizado',
        custom: true,
        img: '/vue_form/profile-custom-unisex.png',
        attributes: {
          heightRange: {
            min: 150,
            max: 210,
            text: 'Customizado'
          },
          suiteDressSizeRange: {
            min: 34,
            max: 46,
            text: 'Customizado'
          },
          bustRange: {
            min: 76,
            max: 140,
            text: 'Customizado'
          },
          waistRange: {
            min: 60,
            max: 140,
            text: 'Customizado'
          },
          hipsRange: {
            min: 85,
            max: 140,
            text: 'Customizado'
          },
          shoesSizeRange: {
            min: 34,
            max: 44,
            text: 'Customizado'
          }
        }
      }
    ],
    event: [
      {
        name: 'Padrão para Feminino',
        custom: false,
        img: '/vue_form/event.png',
        attributes: {
          heightRange: {
            min: 150,
            max: 210,
            text: 'De 1,50m a 2,10m'
          },
          suiteDressSizeRange: {
            min: 34,
            max: 42,
            text: 'De 34 a 42'
          },
          bustRange: {
            min: 76,
            max: 140,
            text: 'De 76cm a 140cm'
          },
          waistRange: {
            min: 60,
            max: 140,
            text: 'De 60cm a 140cm'
          },
          hipsRange: {
            min: 85,
            max: 140,
            text: 'De 85cm a 140cm'
          },
          shoesSizeRange: {
            min: 33,
            max: 48,
            text: 'De 33 a 48'
          }
        }
      },
      {
        name: 'Personalizado',
        custom: true,
        img: '/vue_form/profile-custom-female.jpg',
        attributes: {
          heightRange: {
            min: 150,
            max: 210,
            text: 'Customizado'
          },
          suiteDressSizeRange: {
            min: 34,
            max: 42,
            text: 'Customizado'
          },
          bustRange: {
            min: 76,
            max: 140,
            text: 'Customizado'
          },
          waistRange: {
            min: 60,
            max: 140,
            text: 'Customizado'
          },
          hipsRange: {
            min: 85,
            max: 140,
            text: 'Customizado'
          },
          shoesSizeRange: {
            min: 34,
            max: 44,
            text: 'Customizado'
          }
        }
      }
    ]
  },
  eyeColorOptions: [
    { text: 'Castanho Escuro', value: 'eyes_dark_brown' },
    { text: 'Castanho Claro', value: 'eyes_light_brown' },
    { text: 'Verde', value: 'eyes_green' },
    { text: 'Azul', value: 'eyes_blue' }
  ],
  hairColorOptions: [
    { text: 'Castanho Escuro', value: 'hair_dark_brunette' },
    { text: 'Castanho Claro', value: 'hair_light_brunette' },
    { text: 'Loiro', value: 'hair_blond' },
    { text: 'Ruivo', value: 'hair_red' },
    { text: 'Grisalho', value: 'hair_grey' },
    { text: 'Colorido', value: 'colorful_hair' },
    { text: 'Careca', value: 'bald_hair' }
  ],
  skinColorOptions: [
    { text: 'Branca', value: 'skin_white' },
    { text: 'Morena', value: 'skin_brown' },
    { text: 'Negra', value: 'skin_black' },
    { text: 'Oriental', value: 'skin_oriental' }
  ],
  steps: [
    'jobTypes',
    'description',
    'price',
    'extraExpenses',
    'genderAndAmount',
    'ageRange',
    'profiles',
    'location',
    'jobDate',
    'submit'
  ],
  currentStep: 0,
  customizationSteps: [
    'heightRange',
    'suiteDressSizeRange',
    'extraFeatures',
    'bustRange',
    'waistRange',
    'hipsRange',
    'shoesSizeRange'
  ],
  currentCustomizationStep: 0,
  selectedJobTypeIndex: null,
  selectedProfileIndex: 0,
  job: {
    jobType: null,
    title: null,
    description: '',
    remuneration: null,
    imageRights: null,
    feeding: null,
    transportation: null,
    videoType: null,
    requiredCount: 1,
    sex: null,
    ageRange: {
      min: 18,
      max: 45
    },
    heightRange: {
      min: 150,
      max: 200
    },
    suiteDressSizeRange: {
      min: 34,
      max: 48
    },
    bustRange: {
      min: 74,
      max: 90
    },
    hipsRange: {
      min: 85,
      max: 140
    },
    waistRange: {
      min: 60,
      max: 140
    },
    shoesSizeRange: {
      min: 34,
      max: 44
    },
    eyesColors: [],
    hairColors: [],
    skinColors: [],
    state: null,
    city: null,
    dateRange: '',
    formatedDateRange: [],
    startTime: '09:00',
    endTime: '17:00',
    profile: null,
    dateMayChange: false,
    placements: [],
    showroom_photo: null
  }
};
const getters = {
  getCurrentStep(state) {
    return state.steps[state.currentStep];
  },
  getCurrentCustomizationStep(state) {
    return state.customizationSteps[state.currentCustomizationStep];
  },
  getBooleanOfCustomizationSteps(state) {
    return state.currentCustomizationStep < state.customizationSteps.length - 1;
  },
  getCutomizationStepIndex(state) {
    return state.currentCustomizationStep;
  },
  getSelectedJobType(state) {
    return state.jobTypes[state.selectedJobTypeIndex];
  },
  getDescription(state) {
    return state.job.description;
  },
  getTitle(state) {
    return state.job.title;
  },
  getPrice(state) {
    return state.job.remuneration;
  },
  getVideoType(state) {
    return state.job.videoType;
  },
  getImageRights(state) {
    return state.job.imageRights;
  },
  getFeeding(state) {
    return state.job.feeding;
  },
  getTransportation(state) {
    return state.job.transportation;
  },
  getSexOptions(state) {
    return state.sexOptions
  },
  getSex(state) {
    return state.job.sex;
  },
  getRequiredCount(state){
    return state.job.requiredCount;
  },
  getAgeRange(state) {
    return [state.job.ageRange.min, state.job.ageRange.max];
  },
  getFashionModelProfiles(state) {
    if (state.job.sex === 'male') {
      return state.fashionModelProfiles.male
    } else if (state.job.sex === 'unisex') {
      return state.fashionModelProfiles.unisex;
    } else if (state.job.sex === 'female' && (state.job.jobType === 'event' || state.job.jobType === 'activation')) {
      return state.fashionModelProfiles.event;
    } else {
      return state.fashionModelProfiles.female;
    }
  },
  getSelectedProfile(state) {
    if (state.job.sex === 'male') {
      return state.fashionModelProfiles.male[state.selectedProfileIndex];
    } else if (state.job.sex === 'unisex') {
      return state.fashionModelProfiles.unisex[state.selectedProfileIndex];
    } else if (state.job.sex === 'female' && (state.job.jobType === 'event' || state.job.jobType === 'activation')) {
      return state.fashionModelProfiles.event[state.selectedProfileIndex];
    } else {
      return state.fashionModelProfiles.female[state.selectedProfileIndex];
    }
  },
  getEyeColorOptions(state) {
    return state.eyeColorOptions
  },
  getEyesColors(state) {
    return state.job.eyesColors;
  },
  getHairColorOptions(state) {
    return state.hairColorOptions
  },
  getHairColors(state) {
    return state.job.hairColors;
  },
  getSkinColorOptions(state) {
    return state.skinColorOptions
  },
  getSkinColors(state) {
    return state.job.skinColors;
  },
  getHeightRange(state) {
    return [state.job.heightRange.min, state.job.heightRange.max];
  },
  getSuiteDressSizeRange(state) {
    return [state.job.suiteDressSizeRange.min, state.job.suiteDressSizeRange.max];
  },
  getBustRange(state) {
    return [state.job.bustRange.min, state.job.bustRange.max];
  },
  getHipsRange(state) {
    return [state.job.hipsRange.min, state.job.hipsRange.max];
  },
  getWaistRange(state) {
    return [state.job.waistRange.min, state.job.waistRange.max];
  },
  getShoesSizeRange(state) {
    return [state.job.shoesSizeRange.min, state.job.shoesSizeRange.max];
  },
  getSelectedState(state) {
    return state.job.state;
  },
  getSelectedCity(state) {
    return state.job.city;
  },
  getDateRange(state) {
    return state.job.dateRange;
  },
  getFormatedDateRange(state) {
    return state.job.formatedDateRange;
  },
  getStartTime(state) {
    return state.job.startTime;
  },
  getEndTime(state) {
    return state.job.endTime;
  },
  getFinishedJob(state) {
    return { job:
      {
        serialized_job:
        {
          job_type: state.job.jobType,
          title: state.job.title,
          description: state.job.description,
          remuneration: state.job.remuneration,
          required_count: state.job.requiredCount,
          sex: state.job.sex,
          city: state.job.city ? state.job.city.value : '',
          state: state.job.state? state.job.state.value : '',
          age_range: `${state.job.ageRange.min}; ${state.job.ageRange.max}`,
          height_range: `${state.job.heightRange.min / 100}; ${state.job.heightRange.max / 100}`,
          bust_range: `${state.job.bustRange.min}; ${state.job.bustRange.max}`,
          waist_range: `${state.job.waistRange.min}; ${state.job.waistRange.max}`,
          hips_range: `${state.job.hipsRange.min}; ${state.job.hipsRange.max}`,
          suite_dress_size_range: `${state.job.suiteDressSizeRange.min}; ${state.job.suiteDressSizeRange.max}`,
          shoe_size_range: `${state.job.shoesSizeRange.min}; ${state.job.shoesSizeRange.max}`,
          eyes_colors: state.job.eyesColors.length === 0 ? [''] : state.job.eyesColors,
          hair_colors: state.job.hairColors.length === 0 ? [''] : state.job.hairColors,
          skin_colors: state.job.skinColors.length === 0 ? [''] : state.job.skinColors,
          date_range: state.job.formatedDateRange.join(";"),
          start_time: state.job.startTime[0] === '0' ? state.job.startTime.substr(1) : state.job.startTime,
          end_time:  state.job.endTime[0] === '0' ? state.job.endTime.substr(1) : state.job.endTime,
          image_rights: state.job.imageRights,
          transportation: state.job.transportation,
          feeding: state.job.feeding,
          date_may_change: state.job.dateMayChange,
          placements: state.job.placements,
          showroom_photo: state.job.showroom_photo
        }
      }
    }
  },
  getDateMayChange(state) {
    return state.job.dateMayChange;
  },
  getPlacements(state) {
    return state.job.placements;
  },
  getshowroomPhoto(state) {
    return state.job.showroom_photo;
  }
};
const mutations = {
  setJobType(state, jobType) {
    state.job.jobType = jobType.databaseName;
    state.selectedJobTypeIndex = jobType.index;
  },
  changeStep(state, payload) {
    state.currentStep += payload;
  },
  setStep(state, payload) {
    state.currentStep = payload;
  },
  changeCustomizationStep(state, payload) {
    state.currentCustomizationStep += payload;
  },
  addNewStep(state, payload) {
    state.steps.splice(state.currentStep + 1, 0, payload);
  },
  removeStep(state, payload) {
    if (state.steps.indexOf(payload) > 0){
      state.steps.splice(state.steps.indexOf(payload), 1);
    }
  },
  updateDescription(state, text) {
    state.job.description = text;
  },
  updateTitle(state, text) {
    state.job.title = text;
  },
  updatePrice(state, amount) {
    state.job.remuneration = amount;
  },
  updateVideoType(state, value) {
    state.job.videoType = value;
  },
  updateImageRights(state, value) {
    state.job.imageRights = value;
  },
  updateFeeding(state, value) {
    state.job.feeding = value;
  },
  updateTransportation(state, value) {
    state.job.transportation = value;
  },
  updateSex(state, value) {
    state.job.sex = value;
  },
  updateRequiredCount(state, value) {
    state.job.requiredCount = value;
  },
  updateAgeRange(state, range) {
    if (range[0]) { state.job.ageRange.min = range[0] };
    if (range[0]) { state.job.ageRange.max = range[1] };
  },
  updateSelectedProfileIndex(state, index) {
    state.selectedProfileIndex = index;
  },
  updateHeightRange(state, range) {
    if (range[0]) { state.job.heightRange.min = range[0] };
    if (range[0]) { state.job.heightRange.max = range[1] };
  },
  updateSuiteDressSizeRange(state, range) {
    if (range[0]) { state.job.suiteDressSizeRange.min = range[0] };
    if (range[0]) { state.job.suiteDressSizeRange.max = range[1] };
  },
  updateBustRange(state, range) {
    if (range[0]) { state.job.bustRange.min = range[0] };
    if (range[0]) { state.job.bustRange.max = range[1] };
  },
  updateHipsRange(state, range) {
    if (range[0]) { state.job.hipsRange.min = range[0] };
    if (range[0]) { state.job.hipsRange.max = range[1] };
  },
  updateWaistRange(state, range) {
    if (range[0]) { state.job.waistRange.min = range[0] };
    if (range[0]) { state.job.waistRange.max = range[1] };
  },
  updateShoesSizeRange(state, range) {
    if (range[0]) { state.job.shoesSizeRange.min = range[0] };
    if (range[0]) { state.job.shoesSizeRange.max = range[1] };
  },
  updateEyesColors(state, options) {
    state.job.eyesColors = options;
  },
  updateHairColors(state, options) {
    state.job.hairColors = options;
  },
  updateSkinColors(state, options) {
    state.job.skinColors = options;
  },
  updateState(state, option) {
    state.job.state = option;
  },
  updateCity(state, option) {
    state.job.city = option;
  },
  updateDateRange(state, range) {
    state.job.dateRange = range;
    let rangeArray = [];
    let index = 0;
    for(index; index < range.length; index++) {
      rangeArray.push(range[index].getDate() + '/' + (+range[index].getMonth() + 1) + '/' + range[index].getFullYear())
    };
    state.job.formatedDateRange = rangeArray;
  },
  updateStartTime(state, time) {
    state.job.startTime = time;
  },
  updateEndTime(state, time) {
    state.job.endTime = time;
  },
  updateDateMayChange(state, option){
    state.job.dateMayChange = option;
  },
  updatePlacements(state, options) {
    state.job.placements = options;
  },
  updateshowroomPhoto(state, value) {
    state.job.showroom_photo = value;
  }
};
const actions = {
  setJobType({ commit }, jobType) {
    commit('setJobType', jobType);
  },
  changeStep({ commit }, payload) {
    commit('changeStep', payload);
  },
  changeCustomizationStep({ commit }, payload) {
    commit('changeCustomizationStep', payload);
  },
  addNewStep({ commit }, step) {
    commit('addNewStep', step);
  },
  removeStep({ commit }, step) {
    commit('removeStep', step);
  },
  updateDescription({ commit }, text) {
    commit('updateDescription', text);
  },
  updateTitle({ commit }, text) {
    commit('updateTitle', text);
  },
  updatePrice({ commit }, amount) {
    commit('updatePrice', amount);
  },
  updateImageRights({ commit }, value) {
    commit('updateImageRights', value);
  },
  updateFeeding({ commit }, value) {
    commit('updateFeeding', value);
  },
  updateTransportation({ commit }, value) {
    commit('updateTransportation', value);
  },
  updateVideoType({ commit }, value) {
    commit('updateVideoType', value);
  },
  updateSex({ commit }, value) {
    commit('updateSex', value);
  },
  updateRequiredCount({ commit }, value) {
    commit('updateRequiredCount', value);
  },
  updateAgeRange({ commit }, range) {
    commit('updateAgeRange', range);
  },
  updateSelectedProfileIndex({ commit }, index) {
    commit('updateSelectedProfileIndex', index);
  },
  updateHeightRange({ commit }, range) {
    commit('updateHeightRange', range);
  },
  updateSuiteDressSizeRange({ commit }, range) {
    commit('updateSuiteDressSizeRange', range);
  },
  updateBustRange({ commit }, range) {
    commit('updateBustRange', range);
  },
  updateHipsRange({ commit }, range) {
    commit('updateHipsRange', range);
  },
  updateWaistRange({ commit }, range) {
    commit('updateWaistRange', range);
  },
  updateShoesSizeRange({ commit }, range) {
    commit('updateShoesSizeRange', range);
  },
  updateEyesColors({ commit }, options) {
    commit('updateEyesColors',options);
  },
  updateHairColors({ commit }, options) {
    commit('updateHairColors',options);
  },
  updateSkinColors({ commit }, options) {
    commit('updateSkinColors',options);
  },
  updateState({ commit }, option) {
    commit('updateState', option);
  },
  updateCity({ commit }, option) {
    commit('updateCity', option);
  },
  updateDateRange({ commit }, range) {
    commit('updateDateRange', range);
  },
  updateStartTime({ commit }, time) {
    commit('updateStartTime', time);
  },
  updateEndTime({ commit }, time) {
    commit('updateEndTime', time);
  },
  updateDateMayChange({ commit }, option) {
    commit('updateDateMayChange', option);
  },
  updatePlacements({ commit }, options) {
    commit('updatePlacements',options);
  },
  updateshowroomPhoto({commit}, value) {
    commit('updateshowroomPhoto', value);
  },
  setFields({ commit, dispatch }, jobExample) {
    dispatch('setJobType', jobExample);
    dispatch('updateDescription', jobExample.description);
    dispatch('updateTitle', jobExample.title);
    dispatch('updatePrice', jobExample.remuneration);
    dispatch('updateImageRights', jobExample.imageRights);
    dispatch('updatePlacements', jobExample.placements);
    dispatch('updateFeeding', jobExample.feeding);
    dispatch('updateTransportation', jobExample.transportation);
    commit('setStep', 1);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
