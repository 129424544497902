import axios from "axios";

const state = {
  brazilianLocations: []
};
const getters = {
  getBrazilianLocations(state) {
    return state.brazilianLocations;
  },
  getBrazilianStates(state) {
    return state.brazilianLocations.map((location) => {
      return location.brazilianState;
    });
  }
};
const mutations = {
  storeLocations(state, brazilianLocations) {
    state.brazilianLocations = brazilianLocations;
  }
};
const actions = {
  loadLocations({ commit }) {
    return axios.get("/cities_brazil/show.json")
      .then((response) => {
        const brazilianLocations = response.data.estados.map((brazilianState) => {
          if (brazilianState.capital) {
            brazilianState.cidades.splice(brazilianState.cidades.indexOf(brazilianState.capital), 1);
            brazilianState.cidades.unshift(brazilianState.capital);
          }
          return {
            brazilianState: { text: brazilianState.nome, value: brazilianState.sigla },
            cities: brazilianState.cidades.map((city) => {
              return {
                text: city,
                value: city
              };
            })
          };
        });
        commit("storeLocations", brazilianLocations);
        return brazilianLocations;
      });
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
