import Vue from 'vue';
import Vuex from 'vuex';
import newJob from './modules/new_job.js';
import locations from './modules/locations.js';
import jobExamples from './modules/job_examples.js';
Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    newJob,
    locations,
    jobExamples
  }
})
