<template>

  <el-form>
    <h5 class="text-center pb-3 p-b-1">Local do Job</h5>
    
    <el-form-item
      :class="{ 'is-error' : stateError }">
      <p class="modal-form-item__label">Selecione um Estado</p>

      <search-input 
        :options="states"
        v-model="selectedState"
        id="state_select"
        placeholder="Selecione um Estado"/>
      
      <div v-if="stateError" class="el-form-item__error">
        {{ stateError }}
      </div>

    </el-form-item>

    <el-form-item
      :class="{ 'is-error' : cityError }">
      <p class="modal-form-item__label">
        Selecione uma Cidade
      </p>
        <search-input 
          :options="cities"
          v-model="selectedCity"
          id="city_select"
          placeholder="Selecione uma Cidade"/>

      <div v-if="cityError" class="el-form-item__error">
        {{ cityError }}
      </div>

    </el-form-item>

  </el-form>

</template>

<script>
  import { required } from 'vuelidate/lib/validators';
  import searchInput from './shared/searchInput';

  export default {
    components: {
      searchInput
    },
    props: {
      validate: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      locations(){
        return this.$store.getters['locations/getBrazilianLocations']
      },
      states(){
        return this.$store.getters['locations/getBrazilianStates']
      },
      cities(){
        if (this.selectedState) {
          const el = this.locations.find(location => location.brazilianState === this.selectedState);
          const index = this.locations.indexOf(el);
          return this.locations[index].cities;
        }
      },
      selectedState: {
        get() {
          return this.$store.getters['newJob/getSelectedState'];
        },
        set(value) {
          this.$store.dispatch('newJob/updateCity', null);
          this.$store.dispatch('newJob/updateState', value);
          this.updateSituation();
        }
      },
      stateError(){
        if(this.validate) {
          if(!this.$v.selectedState.required) {
            return 'Selecione um Estado';
          }
        }
        return false
      },
      selectedCity: {
        get() {
          return this.$store.getters['newJob/getSelectedCity'];
        },
        set(value) {
          this.$store.dispatch('newJob/updateCity', value);
          this.updateSituation();
        }
      },
      cityError(){
        if(this.validate) {
          if(!this.$v.selectedCity.required) {
            return 'Selecione uma Cidade';
          }
        }
        return false
      }
    },
    methods: {
      updateSituation() {
        if (this.$v.$invalid) {
          this.$emit('formError', true);
        } else {
          this.$emit('formError', false);
        }
      }
    },
    validations: {
      selectedState: {
        required
      },
      selectedCity: {
        required
      }
    },
    created() {
      if(!this.selectedState || !this.selectedCity){
        this.$emit('formError', true);
      } else {
        this.$emit('formError', false);
      };
    }
  };
</script>