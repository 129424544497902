<template>
  <el-form>
    <div class="text-center pb-3 p-b-3">
      <h5>Faixa Etária:</h5>
      <span class="py-3 p-y-3 measures">{{ ageRange[0] }} ~ {{ ageRange[1] }} anos</span>
    </div>
    <el-slider
      class="mb-3 m-b-1"
      v-model="ageRange"
      range
      :show-tooltip="false"
      :min="18"
      :max="70" />

  </el-form>
</template>

<script>
  export default {
    computed: {
      ageRange: {
        get() {
          return this.$store.getters['newJob/getAgeRange'];
        },
        set(value) {
          this.$store.dispatch('newJob/updateAgeRange',value);
        }
      }
    },
    methods: {
      submit() {
        this.$store.dispatch('newJob/changeStep', 1);
      }
    },
    created() {
      this.$emit('formError', false);
    }
  };
</script>