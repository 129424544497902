<template>
  <el-form label-position="top">
    <h5 class="text-center pb-3 p-b-1">Adicionais</h5>
    <el-form-item
      v-if="jobType.imageRights"
      :class="{ 'is-error': imageRightsError }">
      <p class="modal-form-item__label">Direitos de Imagem</p>
      <el-select
        v-model.lazy="imageRights"
        label="Tempo de divulgação das imagens / Direito de imagem"
        id="image_rights_select"
        @change="updateSituation"
        placeholder="Selecione uma opção sobre os Direitos de Imagem"
        style="display: block;">
        <el-option
          v-for="option in imageRightsOptions"
          :key="option.value"
          :value="option.value"
          :label="option.label"/>
      </el-select>
      <div v-if="imageRightsError" class="el-form-item__error">
        {{ imageRightsError }}
      </div>

      <a
        class="hint-link"
        @click="hint = !hint">
        <el-icon
          class="el-icon-question" />
        Dicas sobre Direito de Imagem
      </a>

      <el-dialog
        :visible.sync="hint"
        title="Sobre Direito de Imagem:"
        :modalAppendToBody="false"
        :modal="false"
        :width="modalStyle.width"
        top="17vh">
        <div :style="modalStyle.line">
          <p class="my-3 m-b-1">
            É necessário estabelecer um tempo limite para uso da imagem do modelo/ator em qualquer mídia. Quanto mais mídias, quanto maior o território e o tempo em que os materiais com a imagem do modelo/ator serão utilizados, maiores são as expectativas deles sobre os cachês, influenciando assim na quantidade de modelos/atores que se candidatarão para o seu job.
          </p>
          <p class="my-3 m-y-1">
            Em caso de Redes Sociais (Facebook, Instagram e Youtube) o material publicado dentro do período estabelecido nesta publicação não precisa ser retirado do histórico da página. Apenas não podem ser postadas ou impulsionadas novamente após o término do período.
          </p>
        </div>
        <template slot="footer">
          <el-button @click="hint = false">
            Ok, Entedi
          </el-button>
        </template>
      </el-dialog>

    </el-form-item>
    <el-form-item
      v-if="jobType.imageRights">
      <p class="modal-form-item__label">Veiculação da Campanha</p>
      <el-select
        v-model="placements"
        @change="updateSituation"
        id="placements_select"
        placeholder="Selecione todas que se aplicam"
        style="display: block;"
        multiple>
        <el-option
          v-for="option in placementOptions"
          :key="option.value"
          :value="option.value"
          :label="option.label"/>
      </el-select>
    </el-form-item>

    <el-form-item
      :class="{ 'is-error': transportationError }">
      <p class="modal-form-item__label">Despesas de Transporte</p>
      <el-select
        v-model.lazy="transportation"
        @change="updateSituation"
        id="transportation_select"
        placeholder="Selecione uma opção sobre as despesas de Transporte"
        style="display: block;">
        <el-option
          v-for="option in transportationOptions"
          :key="option.value"
          :value="option.value"
          :label="option.label"/>
      </el-select>
      <div v-if="transportationError" class="el-form-item__error">
        {{ transportationError }}
      </div>
    </el-form-item>

    <el-form-item
      :class="{ 'is-error': feedingError }">
      <p class="modal-form-item__label">Despesas de Alimentação</p>
      <el-select
        v-model.lazy="feeding"
        :options="feedingOptions"
        @change="updateSituation"
        id="feeding_select"
        placeholder="Selecione uma opção sobre as despesas de Alimentação"
        style="display: block;">
        <el-option
          v-for="option in feedingOptions"
          :key="option.value"
          :value="option.value"
          :label="option.label"/>
      </el-select>
      <div v-if="feedingError" class="el-form-item__error">
        {{ feedingError }}
      </div>
    </el-form-item>

  </el-form>
</template>

<script>
  import { required, requiredIf } from 'vuelidate/lib/validators';
  export default {
    props: {
      validate: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        hint: false,
        imageRightsOptions: [
          { label: '2 meses de direito de imagem', value: 1 },
          { label: '6 meses de direito de imagem', value: 2 },
          { label: '1 ano de direito de imagem', value: 3 },
          { label: '2 anos de direito de imagem', value: 4 },
          { label: 'Direito de imagem especificado na descrição', value: 5 }
        ],
        transportationOptions: [
          { label: 'Transporte por conta do Modelo', value: 'transportation_by_fashion_model' },
          { label: 'Transporte pelo Contratante para modelos de outra cidade', value: 'transportation_by_enterprise_out_city' }
        ],
        feedingOptions: [
          { label: 'Alimentação por conta do Modelo', value: 'feeding_by_fashion_model' },
          { label: 'Alimentação fornecida pelo Contratante', value: 'feeding_by_enterprise' }
        ],
        placementOptions: [
          { label: 'Redes Sociais (publicações e stories)',
            value: 'social_networks_publication' },
          { label: 'Banner Site',
            value: 'banner' },
          { label: 'Catálogo E-commerce',
            value: 'e_commerce' },
          { label: 'Redes Sociais (campanhas e banners)',
            value: 'social_networks_banner' },
          { label: 'Impressos Publicitários (folder, banner, poster, flyer, panfleto)',
            value: 'advertising_form' },
          { label: 'TV/Vídeo',
            value: 'tv' },
          { label: 'Outdoor e semelhantes',
            value: 'outdoor' },
          { label: 'Busdoor e semelhantes',
            value: 'busdoor' },
          { label: 'Revista (conteúdo)',
            value: 'magazine_content' },
          { label: 'Revista (capa)',
            value: 'magazine_cover' },
          { label: 'Embalagens e/ou PDV',
            value: 'packaging' }
        ],
        modalStyle: {
          width: window.innerWidth < 767 ? '320px' : '600px',
          line: window.innerWidth < 767 ? 'lineHeight: 16px' : 'lineHeight: inherit'
        }
      }
    },
    computed: {
      jobType() {
        return this.$store.getters['newJob/getSelectedJobType'];
      },
      imageRights: {
        get() {
          return this.$store.getters['newJob/getImageRights'];
        },
        set(value) {
          this.$store.dispatch('newJob/updateImageRights', value);
        }
      },
      imageRightsError() {
        if(this.validate){
          if (!this.$v.imageRights.required) {
            return 'Nao pode ficar em branco';
          };
          return false
        }
      },
      transportation: {
        get() {
          return this.$store.getters['newJob/getTransportation'];
        },
        set(value) {
          this.$store.dispatch('newJob/updateTransportation', value);
        }
      },
      transportationError() {
        if(this.validate){
          if (!this.$v.transportation.required) {
            return 'Nao pode ficar em branco';
          };
          return false
        }
      },
      feeding: {
        get() {
          return this.$store.getters['newJob/getFeeding'];
        },
        set(value) {
          this.$store.dispatch('newJob/updateFeeding', value);
        }
      },
      feedingError() {
        if(this.validate){
          if (!this.$v.feeding.required) {
            return 'Nao pode ficar em branco';
          };
          return false
        }
      },
      placements: {
        get() {
          return this.$store.getters['newJob/getPlacements'];
        },
        set(value) {
          this.$store.dispatch('newJob/updatePlacements', value);
        }
      }
    },
    methods: {
      submit() {
        if (this.$v.$invalid) {
          this.$v.$touch();
        } else {
          this.$store.dispatch('newJob/changeStep', 1);
        }
      },
      updateSituation() {
        if (this.$v.$invalid) {
          this.$emit('formError', true);
        } else {
          this.$emit('formError', false);
        }
      }
    },
    validations: {
      imageRights: {
        required: requiredIf(function () {
          return this.jobType.imageRights;
        })
      },
      transportation: {
        required
      },
      feeding: {
        required
      }
    },
    created() {
      if( (this.jobType.imageRights && !this.imageRights) || !this.transportation || !this.feeding){
        this.$emit('formError', true);
      } else {
        this.$emit('formError', false);
      }
    }
  };
</script>

<style>
</style>
