<template>
  <div>
    <div class="text-center pb-3 p-b-3">
      <h6>Faixa de Altura:</h6>
      <span class="py-3 p-y-3 measures">{{ heightRange[0] }}cm - {{ heightRange[1] }}cm</span>
    </div>

    <el-slider
      class="mb-3 m-b-3"
      v-model="heightRange"
      range
      :show-tooltip="false"
      :min="150"
      :max="220" />
  </div>
</template>

<script>
  export default {
    computed: {
      heightRange: {
        get() {
          return this.$store.getters['newJob/getHeightRange']
        },
        set(value) {
          this.$store.dispatch('newJob/updateHeightRange', value);
        }
      }
    }
  };
</script>
