<template>
  <div>
    <div class="text-center pb-3 p-b-3">
      <h6>Cintura mínimo e máximo:</h6>
      <span class="py-3 p-y-3 measures">{{ waistRange[0] }}cm - {{ waistRange[1] }}cm</span>
    </div>
    <el-slider
      class="mb-3 m-b-3"
      v-model="waistRange"
      range
      :show-tooltip="false"
      :min="60"
      :max="140" />

  </div>
</template>

<script>
  export default {
    computed: {
      waistRange: {
        get() {
          return this.$store.getters['newJob/getWaistRange']
        },
        set(value) {
          this.$store.dispatch('newJob/updateWaistRange', value);
        }
      }
    }
  };
</script>
