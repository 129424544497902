<template>
  <div>
    <h5 class="text-center pb-3 p-b-1">Medidas Personalizadas</h5>
    <component :is="currentStep"></component>
  </div>
</template>

<script>
  import heightRange from './customSteps/heightRange.vue';
  import suiteDressSizeRange from './customSteps/suiteDressSizeRange.vue';
  import extraFeatures from './customSteps/extraFeatures.vue';
  import bustRange from './customSteps/bustRange.vue';
  import hipsRange from './customSteps/hipsRange.vue';
  import waistRange from './customSteps/waistRange.vue';
  import shoesSizeRange from './customSteps/shoesSizeRange.vue';

  export default {
    props: {
      currentStep: {
        tipe: String
      }
    },
    components: {
      heightRange,
      suiteDressSizeRange,
      extraFeatures,
      bustRange,
      hipsRange,
      waistRange,
      shoesSizeRange
    },
    created() {
      this.$emit('formError', false)
    }
  };
</script>
<style>
</style>
