<template>
  <div>
    <div class="text-center pb-3 p-b-3">
      <h6>Manequim mínimo e máximo:</h6>
      <span class="py-3 p-y-3 measures">{{ suiteDressSizeRange[0] }} - {{ suiteDressSizeRange[1] }}</span>
    </div>

    <el-slider
      class="m-b-3 mb-3"
      v-model="suiteDressSizeRange"
      range
      :show-tooltip="false"
      :min="34"
      :max="58" />

  </div>
</template>

<script>
  export default {
    computed: {
      suiteDressSizeRange: {
        get() {
          return this.$store.getters['newJob/getSuiteDressSizeRange']
        },
        set(value) {
          this.$store.dispatch('newJob/updateSuiteDressSizeRange', value);
        }
      }
    }
  };
</script>
