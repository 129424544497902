<template>
  <div>
    <h5 class="text-center pb-3 p-b-1">Qual tipo de job vai realizar?</h5>
    <el-row>
      <el-col :span="8" v-for="(jobType, index) in jobTypeList" :key="index">
        <div class="text-center" style="margin: 16px; cursor: pointer;  min-height: 150px;" @click="setJobType({ databaseName: jobType.databaseName, index: index })">
          <img :src="jobType.file" class="rounded-image img-fluid img-responsive rounded rounded-circle"/>
          <strong>{{ jobType.type }}</strong>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    methods: {
      setJobType(jobType) {
        this.$store.dispatch('newJob/setJobType', jobType);

        // Ao mudar o tipo de job é necessário limpar o sexo pois pode estar selecionado unisex em um job que não é de evento ou ativação
        this.$store.dispatch('newJob/updateSex', null);

        //Também é necessário limpar o videoType
        this.$store.dispatch('newJob/updateVideoType', null);

        //Também é necessário limpar o Preço
        this.$store.dispatch('newJob/updatePrice', null);
        //Também é necessário limpar o Placements e o showroom_photo
        this.$store.dispatch('newJob/updatePlacements', null);
        this.$store.dispatch('newJob/updateshowroomPhoto', null);

        this.$emit('nextStep');
      }
    },
    computed: {
      jobTypeList() {
        return this.$store.state.newJob.jobTypes
      }
    },
    created() {
      this.$emit('formError', false)
    }
  };
</script>

<style>
  .rounded-image {
    border-radius: 50% !important;
  }
</style>
