var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{attrs:{"label-position":"top"}},[_c('h5',{staticClass:"text-center pb-3 p-b-1"},[_vm._v("Datas do Job")]),_vm._v(" "),_c('el-form-item',{class:{ 'is-error': _vm.dateRangeError }},[_c('p',{staticClass:"modal-form-item__label"},[_vm._v("Dias em que os modelos deverão ir ao job")]),_vm._v(" "),(_vm.formatedDateRange.length > 0)?_c('p',[_c('el-tag',{attrs:{"size":"mini","color":"success"}},[_vm._v("\n        "+_vm._s(_vm.dateDisplay)+"\n      ")])],1):_vm._e(),_vm._v(" "),_c('el-date-picker',{attrs:{"id":"job_dates_picker","type":"dates","picker-options":_vm.pickerOptions,"placeholder":"Selecione as datas do job","format":"dd/MM/yyyy"},on:{"blur":_vm.saveBackup,"change":_vm.updateSituation},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_vm._v(" "),(_vm.dateRangeError)?_c('div',{staticClass:"el-form-item__error"},[_vm._v("\n      "+_vm._s(_vm.dateRangeError)+"\n    ")]):_vm._e(),_vm._v(" "),_c('el-checkbox',{model:{value:(_vm.dateMayChange),callback:function ($$v) {_vm.dateMayChange=$$v},expression:"dateMayChange"}},[_vm._v("\n    Data selecionada é uma estimativa\n  ")])],1),_vm._v(" "),_c('el-form-item',{class:{ 'is-error': _vm.timeError }},[_c('p',{staticClass:"modal-form-item__label"},[_vm._v("Horário de Entrada e Saída das Modelos")]),_vm._v(" "),_c('el-row',[_c('el-col',{staticClass:"text-center",attrs:{"span":11}},[_c('el-time-select',{staticStyle:{"width":"100%"},attrs:{"id":"job_start_time","editable":false,"placeholder":"Hora de Entrada","picker-options":{
            start: '00:00',
            step: '00:30',
            end: '23:30' }},on:{"change":_vm.updateSituation},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}})],1),_vm._v(" "),_c('el-col',{staticClass:"text-center",attrs:{"offset":2,"span":11}},[_c('el-time-select',{staticStyle:{"width":"100%"},attrs:{"id":"job_end_time","editable":false,"placeholder":"Hora de Saída","picker-options":{
            start: '00:00',
            step: '00:30',
            end: '23:30'
            }},on:{"change":_vm.updateSituation},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}})],1),_vm._v(" "),_c('el-col',{attrs:{"span":24}},[(_vm.timeError)?_c('div',{staticClass:"el-form-item__error"},[_vm._v("\n          "+_vm._s(_vm.timeError)+"\n        ")]):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }