<template>
  <div>
    <div class="text-center pb-3 p-b-3">
      <h6>Busto/Tórax mínimo e máximo:</h6>
      <span class="py-3 p-y-3 measures">{{ bustRange[0] }}cm - {{ bustRange[1] }}cm</span>
    </div>

    <el-slider
      class="mb-3 m-b-3"
      v-model="bustRange"
      range
      :show-tooltip="false"
      :min="76"
      :max="140" />

  </div>
</template>

<script>
  export default {
    computed: {
      bustRange: {
        get() {
          return this.$store.getters['newJob/getBustRange']
        },
        set(value) {
          this.$store.dispatch('newJob/updateBustRange', value);
        }
      }
    }
  };
</script>
