<template>
  <el-form @submit.prevent @submit.native.prevent>

    <h5 class="text-center pb-3 p-b-1">Sobre o seu job</h5>

    <el-form-item
      :class="{'is-error': titleError }">
      <p class="modal-form-item__label">Título do Job</p>
      <el-input
        :placeholder="jobType.titleExample"
        v-model.lazy="title"
        @change="updateSituation"/>

      <div class="el-form-item__error" v-if="titleError">
        {{ titleError }}
      </div>

    </el-form-item>

    <ul v-if="jobType.descriptionPoints.length > 0" class="mb-3">

      <li v-for="descriptionPoint in jobType.descriptionPoints" class="small my-1" :key="descriptionPoint">{{ descriptionPoint }}</li>

    </ul>
    <el-form-item
      :class="{'is-error': descriptionError }">
      <p class="modal-form-item__label">Descrição do job</p>
      <el-input
        type="textarea"
        :autosize="{ minRows: 6, maxRows: 12 }"
        placeholder="Descreva os detalhes de como será o job. Quanto mais informações mais candidatos você terá para selecionar."
        v-model.lazy="description"
        @change="updateSituation"
        />
      <div v-if="descriptionError" class="el-form-item__error">
        {{ descriptionError }}
      </div>
    </el-form-item>

    <el-form-item
      v-if="jobType.databaseName === 'showroom'"
      :class="{'is-error': showroomPhotoError }">
       <p class="modal-form-item__label">Haverá sessão de fotos durante o Showroom?</p>
      <el-radio-group
        v-model.lazy="showroomPhoto"
        @change="updateSituation">

        <el-radio :label="true">Sim</el-radio>
        <el-radio :label="false">Não</el-radio>
      </el-radio-group>
      <div v-if="showroomPhotoError" class="el-form-item__error">
        {{ showroomPhotoError }}
      </div>
    </el-form-item>
      <el-alert v-if="this.showroomPhoto === true"
                class="text-justify my-3 m-b-1"
                type="info"
                :closable="false"
                show-icon
                id='alertshowroom' >
                Não é permitido que as fotos do Show Room sejam de uso comercial. É permitido que fotos durante showroom sejam publicadas em stories, status de whatsapp ou enviadas para clientes via mensagem. Caso você deseje fotos profissionais para uso comercial durante o showroom, publique um <a style="cursor: pointer;" @click="goBack">Job de foto.</a></el-alert>

  </el-form>
</template>

<script>
  import { required, requiredIf, minLength } from 'vuelidate/lib/validators';
  import { validationMixin } from 'vuelidate'

  const minimumWords = (value) => {
    return value.replace(/\s+/g, " ").trim().split(' ').length > 5;
  };

  const allUppercase = (word) => {
    return word !== word.toUpperCase();
  };

  export default {
    props: {
      validate: {
        type: Boolean,
        default: false
      }
    },
    mixins: [validationMixin],
    computed: {
      jobType() {
        return this.$store.getters['newJob/getSelectedJobType'];
      },
      title: {
        get() {
          return this.$store.getters['newJob/getTitle'];
        },
        set(value) {
          this.$store.dispatch('newJob/updateTitle', value);
        }
      },
      titleError() {
        if(this.validate){
          if(!this.$v.title.required) {
            return 'Não pode ficar em branco'
          };
          if(!this.$v.title.allUppercase) {
            return 'Não pode ser todas as letras maiúsculas'
          };
          return false;
        };
      },
      description: {
        get() {
          return this.$store.getters['newJob/getDescription'];
        },
        set(text) {
          this.$store.dispatch('newJob/updateDescription', text);
        }
      },
      descriptionError() {
        if(this.validate) {
          if(!this.$v.description.required) {
            return 'Nao pode ficar em branco'
          }
          if(!this.$v.description.minimumWords) {
            return 'Descreva melhor o job. Isso é fundamental para atrair mais candidatos'
          }
        };
        return false;
      },
      showroomPhoto:{
        get() {
          return this.$store.getters['newJob/getshowroomPhoto'];
        },
        set(value) {
          this.$store.dispatch('newJob/updateshowroomPhoto', value);
        }
      },
      showroomPhotoError(){
        if(this.validate) {
          if(!this.$v.showroomPhoto.required) {
            return 'Nao pode ficar em branco';
          }
          return false;
        }
      }
    },
    methods: {
      updateSituation() {
        if (this.$v.$invalid) {
          this.$emit('formError', true);
        } else {
          this.$emit('formError', false);
        }
      },
      goBack() {
        this.forward = false;
        if (this.currentStep === 'customProfile' && this.cutomizationStepIndex !== 0){
          this.$store.dispatch('newJob/changeCustomizationStep', -1);
        } else {
          this.$store.dispatch('newJob/changeStep', -1);
        };
      }
    },
    validations: {
      title: {
        required,
        allUppercase
      },
      description: {
        required,
        minimumWords
      },
      showroomPhoto: {
        required: requiredIf(function () {
          return this.jobType.databaseName == 'showroom';
        })
      },
    },
    created() {
      if(!this.description || (!this.$v.showroomPhoto.required) || !this.title){
        this.$emit('formError', true);
      } else {
        this.$emit('formError', false);
      }
    }
  };
</script>

<style scoped>
  ul {
    margin: 0;
  }
  li {
    list-style: disc;
  }
  #alertshowroom{
    word-break: normal;
  }
</style>
