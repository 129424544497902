<template>
    <div  style="width: 100%; position: relative;">
        <div class="el-input el-dropdown-link"
            ref="dropdownMenu">
            <input type="text" 
                @click="showOptions = true;"
                class="el-input__inner"
                @input="search($event.target.value)"
                @blur="inputText = handleBlur()"
                v-model="inputText"
                :placeholder="placeholder">
        </div>
        <div 
            v-if="showOptions"
            class="el-dropdown-menu" 
            style="top: auto; min-width: 260px;" >
            <div class="el-scrollbar">
                <div class="el-select-dropdown__wrap el-scrollbar__wrap" style="overflow-x: hidden;">
                    <div class="el-dropdown-menu__item"
                        v-for="option in filteredOptions" 
                        :key="option.value"
                        v-if="filteredOptions.length > 0">
                        <div @click="select(option)" 
                                style="width:calc(100% + 20px); height: 100%; margin-left: -20px; padding: 0 20px;"> 
                            {{ option.text }}
                        </div>
                    </div>
                    <div class="el-dropdown-menu__item" 
                        v-if="filteredOptions.length === 0">
                        <div>
                            Nenhum Resultado Encontrado
                        </div>
                    </div>
                </div>
            </div>      
        </div>
    </div>
</template>
<script>
export default {
    props: {
        placeholder: {
            type: String,
            default: 'Procurar...'
        },
        options: {
            type: Array,
            default: () => []
        },
        value: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            showOptions: false,
            filteredOptions: [],
            inputText: ''
        }
    },
    watch: {
        options() {
            this.filteredOptions = this.options;
        },
        value() {
            if (this.value) {
                return this.inputText = this.value.text;
            }
            return this.inputText = '';
        }
    },
    methods: {
        replaceSpecialCh(word) {
            word = word.replace(/[ÀÁÂÃÄÅàáâãäå]/,"a");
            word = word.replace(/[ÈÉÊËèéêë]/,"e");
            word = word.replace(/[ÍÎÌÏíîìï]/,"i");
            word = word.replace(/[ÓÔÒÕÖóôòõö]/,"o");
            word = word.replace(/[ÚÛÙÜúûùü]/,"u");
            word = word.replace(/[çÇ]/,"c");
            return word;
        },
        handleBlur() {
            if(this.value) {
                return this.inputText = this.value.text;
            }
            return this.inputText = '';
            this.showOptions = false;
        },
        search(query) {
            if(query) {
                return this.filteredOptions = this.options.filter(option => {
                    const queryExpression = new RegExp(this.replaceSpecialCh(query), 'gi')
                    if (this.replaceSpecialCh(option.text).match(queryExpression)) return option;
                });
            };
            return this.filteredStates = this.states;
        },
        select(option) {
            this.inputText = option.text
            this.$emit('input', option);
            this.showOptions = false;
        },
        documentClick(e){
            let el = this.$refs.dropdownMenu
            let target = e.target
            if ((el !== target) && !el.contains(target)) {
                this.showOptions = false
            }
        }
    },
    created() {
        this.filteredOptions = this.options;
        this.inputText =  this.value ? this.value.text : '';
        document.addEventListener('click', this.documentClick)
    },
    destroyed() {
        document.removeEventListener('click', this.documentClick);
    }
}
</script>
