<template>
  <div>
    <div class="text-center pb-3 p-b-3">
      <h6>Calçado mínimo e máximo:</h6>
      <span class="py-3 p-y-3 measures">{{ shoesSizeRange[0] }} - {{ shoesSizeRange[1] }}</span>
    </div>
    <el-slider
      class="mb-3 m-b-3"
      v-model="shoesSizeRange"
      range
      :min="33"
      :max="48" />

  </div>
</template>

<script>
  export default {
    computed: {
      shoesSizeRange: {
        get() {
          return this.$store.getters['newJob/getShoesSizeRange']
        },
        set(value) {
          this.$store.dispatch('newJob/updateShoesSizeRange', value);
        }
      }
    }
  };
</script>
