<template>
  <el-form label-position="top">
    <h5 class="text-center pb-3 p-b-1">Gênero e Quantidade</h5>

    <el-form-item
      :class="{ 'is-error' : sexError }">
      <p class="modal-form-item__label">Gênero para o Job</p>
      <el-radio-group
        v-model="selectedSex"
        @change="updateSituation">
        <el-radio-button
          v-for="gender in sexOptions"
          :label="gender.value"
          :key="gender.value"
          >
          {{ gender.text }}
        </el-radio-button>
      </el-radio-group>
      <div class="el-form-item__error"
        v-if="sexError">
        {{ sexError }}
      </div>
    </el-form-item>

    <el-form-item
      :class="{ 'is-error': requiredCountError }">
      <p class="modal-form-item__label">De quantas pessoas precisa?</p>
      <el-input-number
        v-model.lazy="$v.requiredCount.$model"
        @change="updateSituation"
        :min="1"/>
      <div class="el-form-item__error"
        v-if="requiredCountError">
        {{ requiredCountError }}
      </div>
    </el-form-item>

  </el-form>
</template>

<script>
  import { required, minValue, numeric } from 'vuelidate/lib/validators';
  export default {
    props: {
      validate: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      selectedSex: {
        get() {
          return this.$store.getters['newJob/getSex'];
        },
        set(value) {
          //Para garantir que sempre vai iniciar na primeira opção
          this.$store.dispatch('newJob/updateSelectedProfileIndex', 0);

          this.$store.dispatch('newJob/updateSex', value);
        }
      },
      sexOptions() {
        let jobType = this.$store.getters['newJob/getSelectedJobType'].databaseName
        let sexOptions = this.$store.getters['newJob/getSexOptions'];
        if(jobType != 'event' && jobType != 'activation' ) {
          // remove unisex quando o job não é de evento ou ativação
          sexOptions = sexOptions.filter( item => !(item.value == 'unisex' ));
        }
        return sexOptions;
      },
      sexError() {
        if(this.validate){
          if (!this.$v.selectedSex.required) {
            return 'Nao pode ficar em branco';
          }
          return false
        }
      },
      requiredCount: {
        get() {
          return this.$store.getters['newJob/getRequiredCount'];
        },
        set(value) {
          this.$store.dispatch('newJob/updateRequiredCount', value);
        }
      },
      requiredCountError() {
        if(this.validate){
          if(!this.$v.requiredCount.required){
            return 'Nao pode ficar em branco';
          };
          if(!this.$v.requiredCount.numeric){
            return 'Precisa ser um numeral';
          };
          if(!this.$v.requiredCount.minValue){
            return 'Pelo menos um modelo é necessário para um job';
          }
        }
        return false;
      }
    },
    methods: {
      updateSituation() {
        if (this.$v.$invalid) {
          this.$emit('formError', true);
        } else {
          this.$emit('formError', false);
        }
      }
    },
    validations: {
      selectedSex: {
        required
      },
      requiredCount: {
        required,
        numeric,
        minValue: minValue(1)
      }
    },
    created() {
      if(!this.selectedSex || !this.requiredCount){
        this.$emit('formError', true);
      } else {
        this.$emit('formError', false);
      }
    }
  };
</script>
