
<template>
  <div>
    <a
      v-if="btnKlass !== 'hidden'"
      :class="btnKlass"
      id="new_job_form_btn"
      :data-id="jobId"
      @click="showModal">{{ btnText }}</a>

    <el-dialog
      width="700px"
      :fullscreen="fullscreen"
      :visible.sync="visible"
      lock-scroll
      :before-close="handleClose"
      :close-on-click-modal="false"
      class="text-left">

      <template slot="title">
        <div style="min-height: 26px;">
          <a
            v-if="buttonSteps"
            style="cursor: pointer;"
            @click="goBack">
            <i class="el-icon-arrow-left"/>
            Voltar
          </a>
          <img src="/logo_small.png" style="height:24px; position: absolute; top: 20px; right: calc(50% - 18px);">
        </div>
      </template>

      <transition enter-active-class="fadeIn" :leave-active-class="leaveAnimation" mode="out-in">
        <component
          :is="currentStep"
          @formError="validateForm"
          :validate="validate"
          :currentStep="currentCustomizationStep"
          style="background-color: rgba(0, 0, 0, 0.02); padding: 20px; animation-duration: 0.3s; margin: -20px -10px;"
          :style="modalBodyHeight"
          @nextStep="next"
          @postJob="dispatchJob"
          :sorting="sorting"></component>
      </transition>

      <template slot="footer">
        <div class="text-center" v-if="buttonSteps">

          <el-button
            :loading="this.loading"
            type="primary"
            @click="next">
            Continuar
            <i class="el-icon-arrow-right"/>
          </el-button>

        </div>
        <el-button v-else style="opacity: 0;"></el-button>
      </template>

    </el-dialog>
  </div>


</template>

<script>
  import axios from 'axios';
  import jobTypes from './JobTypes.vue';
  import description from './Description.vue';
  import extraExpenses from './ExtraExpenses.vue';
  import price from './Price.vue';
  import genderAndAmount from './genderAndAmount.vue';
  import ageRange from './ageRange.vue';
  import profiles from './profiles.vue';
  import customProfile from './customProfile.vue';
  import location from './location.vue';
  import jobDate from './jobDate.vue';
  import error from './Error.vue';
  import submit from './Submit.vue';

  export default {
    components: {
      jobTypes,
      description,
      price,
      extraExpenses,
      genderAndAmount,
      ageRange,
      profiles,
      customProfile,
      location,
      jobDate,
      error,
      submit,
    },
    props: {
      jobId: {
        type: String,
        default: '0'
      },
      btnText: {
        type: String,
        default: 'Postar um Job'
      },
      sorting: {
        type: Boolean,
        default: true
      },
      btnKlass: {
        type: String,
        default: 'vue-job-form btn btn-success text-white bold-text'
      },
      user: {
        type: Boolean,
        default: false
      },
      token: {
        type: String,
        default: ''
      },
      state: {
        type: String,
        default: undefined
      },
      city: {
        type: String,
        default: undefined
      }
    },
    created() {
      const urlParams = new URLSearchParams(window.location.search);
      const param = urlParams.get('job_type');
      const jobTypes = { 'activation': 0, 'event': 1, 'fashion': 2, 'photo': 3, 'video': 4, 'showroom': 5 }

      if(param) {
        this.$store.dispatch('newJob/setJobType', { index: jobTypes[param], databaseName: param })
        this.$store.dispatch('newJob/changeStep', 1)
      }

      this.$store.dispatch('newJob/updateImageRights', null);
      this.$store.dispatch('newJob/updateState', null);
      this.$store.dispatch('newJob/updateCity', null);
      this.$store.dispatch('newJob/updateDateRange', []);
      this.$store
        .dispatch('locations/loadLocations')
        .then(brazilianLocations => {
          if(this.state && this.city) {
            let s;
            for(let i = 0; i < brazilianLocations.length; i++) {
              if (brazilianLocations[i].brazilianState.value === this.state) {
                s = brazilianLocations[i].brazilianState
                break;
              }
            }
            this.$store.dispatch('newJob/updateState', s);
            this.$store.dispatch('newJob/updateCity', { text: this.city, value: this.city })
          }
        })
      const id = parseInt(this.jobId)
      if (id > 0) {
        const jobExample = this.$store.getters['jobExamples/getJobExampleById'](id);
        this.$store.dispatch('newJob/setFields', jobExample);
      }
    },
    data() {
      return {
        visible: true,
        error: false,
        validate: false,
        forward: true,
        loading: false
      }
    },
    computed: {
      currentStep() {
        return this.$store.getters['newJob/getCurrentStep'];
      },
      currentCustomizationStep() {
        return this.$store.getters['newJob/getCurrentCustomizationStep'];
      },
      isCustomizationStep() {
        return this.$store.getters['newJob/getBooleanOfCustomizationSteps'];
      },
      cutomizationStepIndex() {
        return this.$store.getters['newJob/getCutomizationStepIndex'];
      },
      fullscreen(){
        return window.innerWidth < 767;
      },
      modalStyle() {
        return {
          height: (window.innerWidth < 767 ? '100vh' : '627px'),
          top: (window.innerWidth < 767 ? '0' : '100px'),
          margin: '0 auto'
        }
      },
      modalBodyHeight() {
        return window.innerWidth < 767? 'min-height: calc(100vh - 134px)' : 'min-height: 520px'
      },
      job() {
        return this.$store.getters['newJob/getJob'];
      },
      finnishedJob() {
        return this.$store.getters['newJob/getFinishedJob'];
      },
      leaveAnimation() {
        if (window.innerWidth < 767) {
          if (this.forward){
            return 'slideOutLeft';
          }
          return 'slideOutRight';
        }
        return 'fadeOut';
      },
      buttonSteps() {
        return this.currentStep !== 'jobTypes' && this.currentStep !== 'submit' && this.currentStep !== 'error'
      }
    },
    methods: {
      showModal() {
        this.visible = true
      },
      handleClose(done) {
        if(window.location.href.includes('/enterprise/jobs/new')) {
          window.location.href = '/enterprise/dashboard'
        } else {
          done()
        }
      },
      goBack() {
        this.forward = false;
        if (this.currentStep === 'customProfile' && this.cutomizationStepIndex !== 0){
          this.$store.dispatch('newJob/changeCustomizationStep', -1);
        } else {
          this.$store.dispatch('newJob/changeStep', -1);
        };
      },
      next() {
        this.forward = true;
        this.validate = true;
        if (this.currentStep === 'customProfile' && this.isCustomizationStep){
          this.error = false;
          this.$store.dispatch('newJob/changeCustomizationStep', 1);
        } else {
          if(!this.error) {
            if(this.currentStep === 'jobDate') {
              this.dispatchJob()
            } else {
              this.error = true;
              this.$store.dispatch('newJob/changeStep', 1);
              this.validate = false;
            }
          }
        }
      },
      validateForm(status) {
        this.error = status;
      },
      handleDispatchError() {
        this.$store.dispatch('newJob/addNewStep', 'error');
        this.$store.dispatch('newJob/changeStep', 1);
        this.loading = false;
      },
      dispatchJob() {
        this.loading = true;
        if(this.user) {
          axios
            .post('/enterprise/jobs', { authenticity_token: this.token , job: this.finnishedJob.job.serialized_job })
            .then(response => {
              window.location = response.data.url;
            })
            .catch(error => {
              this.handleDispatchError();
            })
        } else {
          axios
            .post('/anonymous_jobs', this.finnishedJob)
            .then(response => {
              this.$store.dispatch('newJob/changeStep', 1);
            })
            .catch(error => {
              this.handleDispatchError();
            })
          ;
        }
      }
    }
  };

</script>

<style>
  .modal-form-item__label{
    font-size: 16px;
    font-weight: bold;
  }
  .measures {
    font-size: 2rem;
    font-weight: bold;
  }
  .hint-link {
    color: #409EFF !important;
    cursor: pointer;
  }
</style>
