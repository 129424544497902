<template>
  <el-form label-position="top">
    <h5 class="text-center pb-3 p-b-1">Datas do Job</h5>
    <el-form-item
      :class="{ 'is-error': dateRangeError }">
      <p class="modal-form-item__label">Dias em que os modelos deverão ir ao job</p>
      <p v-if="formatedDateRange.length > 0">
        <el-tag
          size="mini"
          color="success">
          {{ dateDisplay }}
        </el-tag>
      </p>
       <el-date-picker
        id="job_dates_picker"
        type="dates"
        v-model="dateRange"
        :picker-options="pickerOptions"
        placeholder="Selecione as datas do job"
        format="dd/MM/yyyy"
        @blur="saveBackup"
        @change="updateSituation"/>

      <div v-if="dateRangeError" class="el-form-item__error">
        {{ dateRangeError }}
      </div>

    <el-checkbox
      v-model="dateMayChange">
      Data selecionada é uma estimativa
    </el-checkbox>

    </el-form-item>
    <el-form-item
      :class="{ 'is-error': timeError }">
      <p class="modal-form-item__label">Horário de Entrada e Saída das Modelos</p>

      <el-row>
        <el-col
          :span="11"
          class="text-center">

          <el-time-select
            id="job_start_time"
            :editable="false"
            style="width: 100%;"
            placeholder="Hora de Entrada"
            v-model="startTime"
            @change="updateSituation"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30' }"/>


        </el-col>
        <el-col
          :offset="2"
          :span="11"
          class="text-center">

          <el-time-select
            id="job_end_time"
            :editable="false"
            style="width: 100%;"
            placeholder="Hora de Saída"
            v-model="endTime"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30'
              }"
            @change="updateSituation"/>

        </el-col>
        <el-col :span="24">
          <div v-if="timeError" class="el-form-item__error">
            {{ timeError }}
          </div>
        </el-col>

      </el-row>
    </el-form-item>

  </el-form>
</template>
<script>
  import { required } from 'vuelidate/lib/validators';
  export default {
    props: {
      validate: {
        type: Boolean,
        default: false,
      },
      sorting: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() <= Date.now();
          },
        },
        dateRangeBackup: [],
        secondBackup: []
      }
    },
    computed: {
      dateRange: {
        get() {
          return this.$store.getters['newJob/getDateRange'];
        },
        set(range) {
          if(range.constructor === Array && !this.checkEquality(range, this.secondBackup)) {
            this.dateRangeBackup = range;
          };
          this.$store.dispatch('newJob/updateDateRange', range);
        }
      },
      dateRangeError(){
        if(this.validate){
          if(!this.$v.dateRange.required) {
            return 'Selecione uma data'
          };
          return false;
        }
      },
      formatedDateRange() {
        return this.$store.getters['newJob/getFormatedDateRange'];
      },
      dateMayChange: {
        get() {
          return this.$store.getters['newJob/getDateMayChange'];
        },
        set(value) {
          this.$store.dispatch('newJob/updateDateMayChange', value);
          this.updateSituation();
        }
      },
      dateDisplay() {
        const dates = [];
        let dateRanges = this.dateRange;
        if(this.sorting) {
          dateRanges = this.dateRange.sort((a, b) => { 
            return new Date(a) - new Date(b);
          }) 
        }
        for(let i = 0; i < dateRanges.length; i++) {
          dates.push(dateRanges[i].getDate() + '/' + (+dateRanges[i].getMonth() + 1));
        }
        return `${dates.length} ${ dates.length > 1 ? 'diárias' : 'diária' } - ${dates.join('; ')}` 
      },
      startTime: {
        get() {
          return this.$store.getters['newJob/getStartTime'];
        },
        set(time) {
          this.$store.dispatch('newJob/updateStartTime', time);
        }
      },
      endTime: {
        get() {
          return this.$store.getters['newJob/getEndTime'];
        },
        set(time) {
          this.$store.dispatch('newJob/updateEndTime', time);
        }
      },
      timeError() {
        if(this.validate){
          if(!this.$v.startTime.required) {
            return 'Selecione a hora de chegada das modelos';
          };
          if(!this.$v.endTime.required) {
            return 'Selecione a hora de saída';
          };
          return false;
        }
      }
    },
    methods: {
      checkEquality(arr1, arr2) {
        if(arr1.length !== arr2.length)
            return false;
        for(var i = arr1.length; i--;) {
            if(arr1[i] !== arr2[i])
                return false;
        }
        return true;
      },
      updateSituation() {
        if (this.$v.$invalid) {
          this.$emit('formError', true);
        } else {
          this.$emit('formError', false);
        }
      },
      saveBackup() {
        this.secondBackup = this.dateRangeBackup
        this.$store.dispatch('newJob/updateDateRange', this.dateRangeBackup);
        this.updateSituation();
      }
    },
    created() {
      if(!this.dateRange.length > 0 || !this.startTime || !this.endTime){
        this.$emit('formError', true);
      } else {
        this.$emit('formError', false);
      }
    },
    validations: {
      dateRange: {
        required
      },
      startTime: {
        required
      },
      endTime: {
        required
      }
    }
  };
</script>

<style>
  .el-date-picker__header .el-picker-panel__icon-btn.el-date-picker__next-btn.el-icon-d-arrow-right {
    display: none !important;
  }
  .el-date-picker__header .el-picker-panel__icon-btn.el-date-picker__next-btn.el-icon-d-arrow-right::before {
    display: none !important;
  }
  .el-date-picker__header .el-picker-panel__icon-btn.el-date-picker__prev-btn.el-icon-d-arrow-left {
    display: none !important;
  }
  .el-date-picker__header .el-picker-panel__icon-btn.el-date-picker__prev-btn.el-icon-d-arrow-left::before {
    display: none !important;
  }
</style>
