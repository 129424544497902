<template>
  <el-form label-position="top">
    <div class="text-center pb-3 p-b-3">
      <h6>Características Adicionais</h6>
    </div>

    <el-form-item>
      <p class="modal-form-item__label">Cor dos Olhos</p>
      <el-select
        size="default"
        mode="multiple"
        v-model="eyesColors"
        class="placeholder-dark"
        multiple
        placeholder="Qualquer Cor dos Olhos">
        <el-option
          v-for="option in eyeColorOptions"
          :key="option.text"
          :label="option.text"
          :value="option.value"/>
      </el-select>
    </el-form-item>
    <el-form-item>
      <p class="modal-form-item__label">Cor do Cabelo</p>
      <el-select
        size="default"
        mode="multiple"
        v-model="hairColors"
        class="placeholder-dark"
        multiple
        placeholder="Qualquer Cor de Cabelo">
        <el-option
          v-for="option in hairColorsOptions"
          :key="option.text"
          :label="option.text"
          :value="option.value"/>
      </el-select>
    </el-form-item>
    <el-form-item>
      <p class="modal-form-item__label">Cor da Pele/Etnia</p>
      <el-select
        size="default"
        mode="multiple"
        v-model="skinColors"
        class="placeholder-dark"
        multiple
        placeholder="Qualquer Cor de Pele/Etnia">
        <el-option
            v-for="option in skinColorsOptions"
            :key="option.text"
            :label="option.text"
            :value="option.value"/>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    computed: {
      ...mapGetters('newJob',
          { eyeColorOptions: 'getEyeColorOptions',
            hairColorsOptions: 'getHairColorOptions',
            skinColorsOptions: 'getSkinColorOptions'
          }
        )
      ,
      eyesColors: {
        get() {
          return this.$store.getters['newJob/getEyesColors'];
        },
        set(value) {
          this.$store.dispatch('newJob/updateEyesColors', value);
        }
      },
      hairColors: {
        get() {
          return this.$store.getters['newJob/getHairColors'];
        },
        set(value) {
          this.$store.dispatch('newJob/updateHairColors', value);
        }
      },
      skinColors: {
        get() {
          return this.$store.getters['newJob/getSkinColors'];
        },
        set(value) {
          this.$store.dispatch('newJob/updateSkinColors', value);
        }
      }
    }
  };
</script>
<style>
  .placeholder-dark input::placeholder {
    color: black;
  }
</style>