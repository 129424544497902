import Vue from 'vue';
import Form from '../components/job_form/Form.vue'
import Vuelidate from 'vuelidate';
import { store } from './../store/store.js';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/pt-br';
import 'element-ui/lib/theme-chalk/index.css';

require('vue2-animate/dist/vue2-animate.min.css');
Vue.use(Vuelidate)
Vue.use(ElementUI, { locale })
const vueArray = [];
const triggers = document.querySelectorAll('.vue-job-form');

const tokenTag = document.getElementsByName('csrf-token');
let token;
if (tokenTag[0]) {
  token = tokenTag[0].content;
}

for (let i = 0; i < triggers.length; i++) {
  triggers[i].addEventListener('click', (event) => {
    const id = event.currentTarget.dataset.id;
    const text = event.currentTarget.innerText;
    const btnKlass = event.currentTarget.dataset.btnklass;
    const state = event.currentTarget.dataset.state;
    const city = event.currentTarget.dataset.city;
    let sorting = true;
    if(vueArray.length > 0) {
      sorting = false;
    }
    const vm = new Vue({
      el: `.vue-job-form[data-id="${id}"]`,
      store,
      render: h => h(Form, {
        props: {
          jobId: id,
          btnText: text,
          btnKlass: btnKlass, 
          sorting: sorting,
          token,
          user: (window.location.href.includes('/enterprise/') ? true : false),
          state: state,
          city: city
        }
      })
    })
    vueArray.push({ vueInstance: vm, id: id });
  })
}

if (document.getElementById('vue-form-page')) {
  const state = document.getElementById('vue-form-page').dataset.state;
  const city = document.getElementById('vue-form-page').dataset.city;
  new Vue({
    el: '#vue-form-page',
    store,
    render: h => h(Form, {
      props: {
        btnKlass: 'hidden', 
        user: true,
        token: token,
        state: state,
        city: city
      }
    })
  })
}