<template>
  <div>
    <h5 class="text-center pb-3 p-b-1">Qual perfil você procura?</h5>
    <el-card>
      <el-carousel
        arrow="always"
        :autoplay="false"
        height="200px"
        indicator-position="none"
        @change="changeProfile"
        type="card">

        <el-carousel-item
          class="text-center"
          v-for="profile in profiles"
          :key="profile.name">

            <div
              style="background-color: transparent;">
              <img v-if="profile.img"
                :src="profile.img"
                :alt="profile.name"
                slot="cover"
                height="200"
                style="margin: 0 auto;"
                />
            </div>
        </el-carousel-item>
      </el-carousel>
      <transition
        name="fade"
        mode="out-in">
        <div
          :key="selectedProfile.name"
          class="text-center"
          style="animation-duration: 0.5s">
          <h5 class="m-b-0 mb-0 mt-3 m-t-2" style="text-transform: uppercase">
            Perfil {{ selectedProfile.name }}
          </h5>

          <p class="mb-1"><strong>Altura: </strong> {{ selectedProfile.attributes.heightRange.text }} </p>
          <p class="mb-1"><strong>Manequim: </strong> {{ selectedProfile.attributes.suiteDressSizeRange.text }} </p>
          <p class="mb-1"><strong>Busto e Torax: </strong> {{ selectedProfile.attributes.bustRange.text }} </p>
          <p class="mb-1"><strong>Cintura: </strong> {{ selectedProfile.attributes.waistRange.text }} </p>
          <p class="mb-1"><strong>Quadril: </strong> {{ selectedProfile.attributes.hipsRange.text }} </p>
          <p class="mb-1"><strong>Cabelo, olhos e pele: </strong>{{ selectedProfile.custom ? 'Customizado' : 'Qualquer cor' }}</p>
          <p class="mb-1"><strong>Calçado: </strong> {{ selectedProfile.attributes.shoesSizeRange.text }} </p>
        </div>
      </transition>
    </el-card>

  </div>
</template>

<script>
  export default {
    computed: {
      profiles() {
        return this.$store.getters['newJob/getFashionModelProfiles'];
      },
      selectedProfile() {
        return this.$store.getters['newJob/getSelectedProfile']
      }
    },
    methods: {
      toArray(obj){
        return Object.values(obj);
      },
      dispatchInfo(attributes) {
        console.log('aqui')
        this.$store.dispatch('newJob/updateHeightRange', this.toArray(attributes.heightRange));
        this.$store.dispatch('newJob/updateSuiteDressSizeRange', this.toArray(attributes.suiteDressSizeRange));
        this.$store.dispatch('newJob/updateBustRange', this.toArray(attributes.bustRange));
        this.$store.dispatch('newJob/updateHipsRange', this.toArray(attributes.hipsRange));
        this.$store.dispatch('newJob/updateWaistRange', this.toArray(attributes.waistRange));
        this.$store.dispatch('newJob/updateShoesSizeRange', this.toArray(attributes.shoesSizeRange));
      },
      addStep() {
        this.$store.dispatch('newJob/addNewStep', 'customProfile');
      },
      removeOldStep() {
        this.$store.dispatch('newJob/removeStep', 'customProfile');
      },
      changeProfile(newSlide, oldSlide) {
        this.$store.dispatch('newJob/updateSelectedProfileIndex', newSlide);
        this.removeOldStep();
        if (this.selectedProfile.custom) { this.addStep() }
        this.dispatchInfo(this.selectedProfile.attributes);
      }
    },
    created() {
      this.$emit('formError', false);

      // Para garantir que os valores do perfil sejam setados mesmo
      // se não houver clique em um perfil
      this.dispatchInfo(this.selectedProfile.attributes)
    }
  };
</script>

<style>
  .display-none{
    margin-bottom: 0;
    display: none;
  }
  .display-none > li {
    display: none;
  }
  .mb-1 {
    margin-bottom: .25rem!important;
  };
</style>
