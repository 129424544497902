<template>
  <el-form  @submit.prevent @submit.native.prevent>
    <div class="mb-3 m-b-2">
      <h5 class="text-center pb-3 p-b-1">Cachê por Modelo por Diária</h5>

      <el-form-item
        v-if="jobType.databaseName === 'video'"
        :class="{ 'is-error': videoTypeError }">
        <div class="modal-form-item__label">Função no Vídeo</div>
        <el-select
          v-model.lazy="videoType"
          @change="updateSituation"
          id="video_type_select"
          placeholder="Função do Modelo no Vídeo"
          style="display: block;">
          <el-option
            v-for="option in videoTypeOptions"
            :key="option.value"
            :value="option.value"
            :label="option.label"/>
        </el-select>
      <div v-if="videoTypeError" class="el-form-item__error">
        {{ videoTypeError }}
      </div>
      </el-form-item>


      <strong class="text-justify">Quem define o valor do cachê é você.</strong>
      <p class="text-justify mb-2 m-b-2">Quanto maior o cachê mais opções de modelos você terá para escolher e maiores são as chances de modelos com mais experiência se candidatarem.</p>
      <a
        class="hint-link"
        @click="hint = !hint">
        <el-icon
          class="el-icon-question" />
        Dicas sobre Cache
      </a>
      <el-dialog
        :visible.sync="hint"
        title="Dicas sobre Cache:"
        :modalAppendToBody="false"
        :modal="false"
        :width="modalStyle.width"
        :style="modalStyle.line"
        top="17vh">
        <div class="text-justify">
          <p class="my-3 m-b-1">O valor influencia diretamente o interesse dos modelos pelo seu job. Leve em consideração que outros fatores influenciam o valor da diária, sendo imprescindível definir um cachê mais alto quanto maior for a duração do job e o tempo de direito de imagem, ou quando o modelo for responsável por gastos como transporte, alimentação e vestuário.</p>
          <p class="my-3 m-y-1">Alguns modelos que possuem uma boa pontuação no Job for Model podem aceitar participar do seu job, porém por um cachê maior. Você não é obrigado é selecionar esses modelos a menos que tenha gostado do perfil e aceite o valor do modelo.</p>
        </div>
      </el-dialog>
    </div>
    <el-col :xs='24' :sm="12">
      <el-form-item :class="{ 'is-error': priceError }">
        <el-input
          v-model="price"
          @blur="price = price.match(/\d*/)[0]"
          type="tel">
            <template slot="prepend">R$</template>
            <template slot="append">
              <a style="font-size: 16px; cursor: pointer; position: relative;" @click="updatePrice()">
                <div style="position: absolute; top: -10px; left: -20px; width: 51px; height: 40px;;" />
                +
              </a>
            </template>
        </el-input>
        <div v-if="priceError" class="el-form-item__error">
          {{ priceError }}
        </div>
        Informe o valor por modelo para uma diária
      </el-form-item>
    </el-col>

  </el-form>
</template>

<script>
  import { required, numeric, requiredIf } from 'vuelidate/lib/validators';
  import { mapGetters } from 'vuex';
  export default {
    props: {
      validate: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        hint: false,
        modalStyle: {
          width: window.innerWidth < 767 ? '320px' : '600px',
          line: window.innerWidth < 767 ? 'lineHeight: 16px' : 'lineHeight: inherit'
        },
        videoTypeOptions: [
          { value: '0', label: 'Ator Principal', minPrice: 300 },
          { value: '1', label: 'Coadjuvante', minPrice: 300 },
          { value: '2', label: 'Figurante', minPrice: 250 },
        ],
      }
    },
    computed: {
      jobType() {
        return this.$store.getters['newJob/getSelectedJobType']
      },
      price: {
        get() {
          return this.$store.getters['newJob/getPrice'];
        },
        set(value) {
          this.$store.dispatch('newJob/updatePrice', value);
          this.updateSituation();
        }
      },
      videoType: {
        get() {
          return this.$store.getters['newJob/getVideoType'];
        },
        set(value) {
          this.$store.dispatch('newJob/updateVideoType', value);
        }
      },
      priceError() {
        if(this.validate){
          if (!this.$v.price.required) {
            return 'Nao pode ficar em branco';
          };
          if (!this.$v.price.numeric) {
            return 'Precisa ser um numeral';
          };
          if (this.jobType.databaseName == 'video' && this.videoType != null) {
            let selectedOption = Number.parseInt(this.videoType, 10);
            let videoMinPrice = this.videoTypeOptions[selectedOption].minPrice;
            let videoLabel = this.videoTypeOptions[selectedOption].label;

            if (this.price < videoMinPrice) {
              return 'Deve ser a partir de R$' + videoMinPrice + ' para ' + videoLabel;
            };

          } else {
            if (this.price < this.jobType.minPrice) {
              return 'Deve ser a partir de R$' + this.jobType.minPrice + ' para ' + this.jobType.type;
            };
          }
          return false;
        }
      },
      videoTypeError() {
        if(this.validate){
          if (!this.$v.videoType.required) {
            return 'Nao pode ficar em branco';
          };

          return false;
        }
      }
    },
    methods: {
      updateSituation() {

        let isPriceValid = (this.jobType.databaseName == 'video' && this.videoType != null) ? this.price >= this.videoTypeOptions[Number.parseInt(this.videoType, 10)].minPrice : this.price >= this.jobType.minPrice

        if (this.$v.$invalid || !isPriceValid) {
          this.$emit('formError', true);
        } else {
          this.$emit('formError', false);
        }
      },
      updatePrice() {
        this.$store.dispatch('newJob/updatePrice', Number(this.price) + 50);
        this.updateSituation();
      }
    },
    validations: {
      price: {
        required,
        numeric
      },
      videoType: {
        required: requiredIf(function () {
          return this.jobType.databaseName == 'video';
        })
      }
    },
    created() {
      let isPriceError = !this.price || this.priceError
      let isVideTypeError = this.jobType.databaseName == 'video' ? !this.videoType || this.videoTypeError : false

      if(isPriceError || isVideTypeError) {
        this.$emit('formError', true);
      } else {
        this.$emit('formError', false);
      }
    }
  };
</script>
<style>
</style>
